<template>
    <div class="webhome-wrapper">
        <el-row class="home-header justify-content-between d-flex">
            <el-col class="header-logo d-flex">
                <div class="logo" :style="{flex: '0 0' + (collapsed? '63px': '229px')}"
                     style="transition: all 200ms ease-in">
                    <div class="image d-inline-block">
                        <img src="../../src/assets/images/applogo.png" alt="applog"/>
                    </div>
                    <span v-if="!collapsed">{{ preset.app.short || "腾讯会议管理平台" }}</span>
                </div>
                <div class="switch" @click.prevent="collapse">
                    <i :class="collapsed?'el-icon-s-unfold':'el-icon-s-fold'"></i>
                </div>
            </el-col>
            <el-col class="userinfo d-flex flex-row-reverse">
                <el-dropdown @command="handleCommand" style="padding-right: 20px;">
                    <div style="cursor: pointer">
                        <div class="userinfo-image"></div>
                        <span class="userinfo-name">您好,{{ user.name }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-user-solid" command="profile">我的档案</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-table-lamp" command="logout" divided>登出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row class="main">
            <aside :class="collapsed?'menu-collapsed':'menu-expanded'" style="overflow-y: auto">
                <!--导航菜单-->
                <el-menu
                    :default-active="$route.path"
                    class="el-menu-vertical-dark"
                    :collapse="collapsed"
                    unique-opened router
                >
                    <template v-for="(item) in menu">
                        <el-submenu :index="item.name" v-if="item.children" :key="item.name">
                            <template slot="title"><i :class="item.icon"></i>
                                <span slot="title">{{ item.name }}</span>
                            </template>
                            <el-menu-item v-for="child in item.children" :index="child.path" :key="child.name">
                                <i v-if="child.icon" :class="child.icon"></i><span slot="title">{{ child.name }}</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-if="!item.children" :index="item.path" :key="item.name">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </aside>
            <section class="content-container">
                <div class="grid-content bg-purple-light">
                    <el-col :span="24" class="breadcrumb-container">
                        <div class="d-flex justify-content-between">
                            <strong class="title"><i class="el-icon-back" style="cursor:pointer" @click="goBack"></i>
                                {{ $route.name }}</strong>
                            <announcement></announcement>
                        </div>
                    </el-col>
                    <el-col :span="24" class="content-wrapper">
                        <keep-alive>
                            <router-view :key="pageKey" v-if="$route.meta && $route.meta.keepAlive"></router-view>
                        </keep-alive>
                        <router-view :key="pageKey" v-if="!($route.meta && $route.meta.keepAlive)"></router-view>
                    </el-col>
                </div>
            </section>
        </el-row>
    </div>
</template>

<script>

import Home from "../router/home";
import {mapState, mapMutations,mapGetters} from "vuex";
import Announcement from "../components/announcememt";

export default {
    components: {Announcement},
    data() {
        return {
            collapsed: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            },
            keepALive: [],
        }
    },
    computed: {
        ...mapState(['user', 'preset']),
        ...mapGetters('process',['isCollapsed']),

        logo() {
            return '/assets/' + this.$organization.org + '/images/logo.png';
        },
        menu() {
            let menu = [];
            const vm = this;
            const level = parseInt(this.user.role_level);
            Home.forEach(function (item) {
                let can = true;
                if (item.meta) {
                    if (item.meta.level) { //判断级别够不够
                        can = item.meta.level <= level;
                        if (!can && item.meta.position) {
                            can = (vm.user.position == item.meta.position);
                        }
                    }
                    if (item.meta.mobile) {
                        can = false;
                    }
                }
                if (!item.hidden && can) {
                    const children = item.children;
                    if (children && children.length > 1) {  // 多个子项
                        let sub_menu = [];
                        children.forEach(function (child) {
                            let can = true;
                            if (child.meta && child.meta.level) { //子项也有权限
                                can = child.meta.level <= level;
                            }
                            if (child.meta && child.meta.mobile) { // 仅仅在手机端显示
                                can = false;
                            }
                            if (can && !(child.meta && child.meta.hidden)) { //子项隐藏
                                sub_menu.push(child);
                            }
                        });
                        menu.push({icon: item.icon, name: item.name, children: sub_menu});

                    } else if (children && children.length === 1) { //单个子目录
                        const child = children[0];
                        menu.push(Object.assign({icon: item.icon, children: false}, child))
                    }
                }
            });
            return menu;
        },
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        }
    },
    methods: {
        ...mapMutations('user', ['clear']),
        ...mapMutations('process', ['setCollapsed']),
        handleCommand(command) {
            if (command === 'logout') this.logout();
            else if (command === 'profile') this.$router.push("/profile");
            else if (command === 'password') this.$router.push("/password");
        },
        logout: function () { //退出登录
            this.$confirm('&nbsp;&nbsp;将要退出系统, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.clear();
                this.$router.replace('/sign-in');
            }).catch(() => {
            });
        },
        //折叠导航栏
        collapse: function () {
            this.collapsed = !this.collapsed;
            this.setCollapsed(this.collapsed);
        },
        goBack() {
            this.$router.back();
        }
    },
    created() {
        this.collapsed = this.isCollapsed;
    },

}

</script>

<style lang="scss">
$color-header: #fff;
$color-aside: #f7f8fa;
$color-scrollbar: #f7f8fa;
$color-title: #333;
$color-title-active: #003f6f;

.webhome-wrapper {
    height: 100%;

    .home-header {
        height: 49px;
        background: $color-header;
        //background: url("../assets/images/mybg.png") cornsilk;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .05), 0 1px 0 rgba(0, 0, 0, .05);
        color: #fff;

        display: block;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 905;
        overflow: hidden;

        .switch {
            height: 49px;
            line-height: 53px;
            cursor: pointer;
            border-left: 1px dotted #ddd;
            padding-left: 3px;
            padding-right: 3px;
            background-color: $color-header;

            i {
                color: #666;
                font-size: 20px;
            }

            &:hover {
                background-color: darken($color-header, 30%);

                i {
                    color: #fff;
                }
            }
        }

        .header-logo {

            .logo {
                line-height: 49px;
                font-size: large;
                color: #333;

                .image {
                    width: 64px;
                    text-align: center;

                    img {
                        height: 40px;
                    }
                }
            }

            .download-client {
                font-size: 14px;
                line-height: 49px;
                padding: 0 15px;
                border-left: solid 1px #f7f7f7;

                a {
                    color: #999;
                    text-decoration: none;
                    transition: color 0.2s ease-in;

                    &:hover {
                        color: #0D4E9F;
                    }
                }
            }
        }


        .title {
            display: inline-block;
            vertical-align: middle;
            height: 49px;
            float: left;
            padding-left: 20px;
        }

        .usermanual {
            float: right;

            .words {
                padding: 13px 20px;
                min-width: 50px;
                background-color: rgba(0, 0, 0, 0.05);
                margin: 0 25px;
                height: 49px;
                cursor: pointer;
                transition: all 0.2s ease-in;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.15);
                }
            }
        }

        .userinfo {
            text-align: right;
            float: right;

            .userinfo-image {
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                width: 30px;
                height: 30px;
                margin-top: 10px;
                border-radius: 100px;

                background: url("../assets/images/avatar.png") no-repeat center top;
                background-size: cover;
            }

            .userinfo-name {
                display: inline-block;
                color: #606266;
                line-height: 49px;
                font-size: 13px;
                float: right;
                margin-left: 10px;
            }
        }


        .title {
            .breadcrumb-inner {
                line-height: 49px;
            }
        }
    }

    .main {
        display: flex;
        position: absolute;
        top: 49px;
        bottom: 0;
        overflow: hidden;
        width: 100%;

        aside {
            flex: 0 0 230px;
            width: 230px;
            display: block;
            background-color: $color-aside;
            overflow-y: auto;
            overflow-x: hidden;
            transition: all 0.2s ease-in;
            -webkit-overflow-scrolling: touch;
            min-height: 100%;

            &::-webkit-scrollbar {
                width: 8px;
                background-color: $color-scrollbar;
            }

            &::-webkit-scrollbar-thumb {
                //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                //border-radius: 10px;
                background-color: darken($color-scrollbar, 20%);
            }

            .el-menu {
                height: 100%;
            }

            .collapsed {
                width: 64px;

                .item {
                    position: relative;
                }

                .submenu {
                    position: absolute;
                    top: 0;
                    left: 64px;
                    z-index: 99999;
                    height: auto;
                    display: none;
                }

            }
        }


        .menu-collapsed {
            flex: 0 0 64px;
            width: 64px;
        }

        @media screen and (max-width: 400px) {
            .menu-collapsed {
                flex: 0 0 0;
                width: 0;
            }
        }

        .menu-expanded {
            flex: 0 0 230px;
            width: 230px;
        }

        .content-container {
            flex: 1;
            overflow-y: auto;
            padding: 20px;
            -webkit-overflow-scrolling: touch;


            .breadcrumb-container {
                //margin-bottom: 15px;
                .title {
                    width: 200px;
                    float: left;
                    color: $color-title;
                    font-size: 20px;
                }
            }

            .content-wrapper {
                box-sizing: border-box;
                padding-top: 20px;
            }
        }
    }
}

.el-menu-vertical-dark {
    &.el-menu {
        background-color: $color-aside;

        .el-submenu__title:hover {
            background-color: lighten($color-aside, 10%);
            color: $color-title-active;
            //font-weight: bold;

            i {
                color: $color-title-active;
                //font-weight: bold;
            }
        }

        .el-submenu__title {
            color: $color-title;

            i {
                color: $color-title;
            }
        }

        .el-menu-item {
            color: $color-title;

            i {
                color: $color-title;
            }

            &.is-active {
                color: $color-title-active;

                i {
                    color: $color-title-active;
                }
            }

            &:focus {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        .el-menu-item:hover {
            background-color: lighten($color-aside, 5%);
            color: $color-title-active;
            //font-weight: bold;

        }

        .el-submenu .el-menu {
            background-color: darken($color-aside, 5%);
        }
    }

    &:not(.el-menu--collapse) {
        width: 230px;
    }
}

</style>
